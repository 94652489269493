.panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  // min-height: 0;
}

.accordion {
  &:after {
    content: '';
  }
}

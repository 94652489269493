.footer {
  font-size: rem(14);
  line-height: 2.28;
  font-weight: 500;
  padding: {
    top: rem(100);
  }
  background: #fff;
  .container {
    max-width: rem(904);
  }
  a {
    &:hover, &:focus, &:active {
      color: $blue;
    }
  }
}

.footer__container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: rem(96);
}

.footer__col {
  max-width: rem(192);
  min-width: rem(192);
  &:not(:last-child) {
    margin-right: rem(32);
  }
}

.footer__caption {
  margin-bottom: rem(16);
  text-transform: uppercase;
}

.footer__links-list {
  color: $gray-text-color;
  font-weight: 300;
}

.footer__social-icons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: rem(12);
  li {
    &:not(:last-child) {
      margin-right: rem(16);
    }
    a {
      max-width: rem(18);
      min-width: rem(18);
      height: rem(18);
    }
  }
}

.footer__additional {
  border-top: 1px solid $border-color;
  color: $gray-text-color;
  font-weight: 300;
  font-size: rem(12);
  line-height: 1.33;
  padding: {
    top: rem(56);
    bottom: rem(56);
  }
  .footer__container {
    padding-bottom: 0;
    justify-content: space-between;
    align-items: center;
  }
  .footer__col {
    max-width: none;
    min-width: 0;
    margin: 0;
  }
  a {
    display: block;
    text-align: right;
  }
}

.footer__logo {
  max-width: rem(54);
  min-width: rem(54);
  height: rem(48);
}

@media (max-width: 660px) {
  .footer,
  .footer__additional {
    padding-top: rem(32);
    font-size: rem(12);
  }
  .footer__container {
    justify-content: space-between;
    padding-bottom: rem(32);
  }
  .footer__additional {
    padding-bottom: rem(32);
  }
  .footer__col {
    max-width: none;
    min-width: 0;
    &:not(:last-child) {
      margin-right: 0;
    }
  }
}

@media (max-width: 540px) {
  .footer__logo {
    min-width: rem(30);
  }
  .footer__additional {
    font-size: rem(10);
    .container {
      padding: 0 rem(10);
    }
  }
}

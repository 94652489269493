.reviews {
  padding: {
    top: rem(96);
    bottom: rem(96);
  }
  background: $gray-bg-color;
}

@media (max-width: 540px) {
  .reviews {
    padding: rem(32) 0;
  }
}

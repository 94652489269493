.articles-list {
  padding: {
    top: rem(96);
    bottom: rem(96);
  }
  background: $darken-gray-bg-color;
  .container {
    max-width: rem(900);
  }
  h2 {
    margin-bottom: rem(15);
    font-size: rem(24);
    line-height: 1.33;
    text-align: left;
  }
  p {
    line-height: 1.7;
  }
}

.articles-list__item {
  background: #fff;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  border-radius: rem(8);
  box-shadow: rem(0 2 4) rgba(145, 161, 187, 0.100006);
  &:not(:last-child) {
    margin-bottom: rem(32);
  }
}

.articles-list__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.articles-list__content {
  padding: rem(32) rem(40) rem(22) rem(32);
}

.articles-list__img {
  min-width: 48%;
  max-width: 48%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  // max-width: rem(416);
}

.articles-list__subtitle {
  font-weight: 500;
  font-size: rem(18);
  line-height: 1.33;
  color: $gray-text-color;
  margin-bottom: rem(12);
}

.articles-list__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $border-color;
  padding: rem(28) rem(32) rem(24);
  a {
    display: block;
    color: $rose;
    text-transform: uppercase;
    font-weight: 500;
    &:hover, &:active, &:focus {
      color: darken($rose, 10%);
    }
  }
}

.articles-list__date {
  display: block;
  text-transform: uppercase;
  color: $gray-text-color;
  font-size: rem(14);
  line-height: 1.7;
}

@media (max-width: 540px) {
  .articles-list {
    padding: {
      top: rem(32);
      bottom: rem(32);
    }
  }
  .articles-list__img {
    max-width: none;
    min-width: 0;
    width: 100%;
  }
  .articles-list__item {
    flex-direction: column;
  }
}

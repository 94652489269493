.back-btn {
  text-transform: uppercase;
  font-weight: 300;
  font-size: rem(14);
  line-height: 1.14;
  padding: rem(9) rem(16) rem(9) rem(12);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #fff;
  border: none;
  border-radius: rem(24);
  box-shadow: 0 rem(2 4) rgba(145, 161, 187, 0.100006);
  position: absolute;
  left: rem(20);
  top: rem(-72);
  z-index: 2;
}

.back-btn__icon {
  margin-right: rem(12);
}

@media (max-width: 540px) {
  .back-btn__icon {
    margin-right: 0;
  }
  .back-btn {
    font-size: 0;
    border-radius: 50%;
    padding: rem(9);
    left: 0;
  }
}

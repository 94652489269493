.first-slide {
  background: url("../img/index/first-slide-bg.png") no-repeat;
  background-size: cover;
  .container {
    max-width: rem(1126);
  }
  h1 {
    margin-bottom: rem(32);
    letter-spacing: -1px;
  }
  &.coffee {
    background: $darken-gray-bg-color;
    .first-slide__content {
      padding-bottom: rem(208);
    }
    .container {
      position: relative;
    }
  }
  &.food {
    background: url("../img/food/first-slide-bg.png") no-repeat;
    background-size: cover;
  }
  &.office {
    background: url("../img/office/first-slide-bg.png") no-repeat;
    background-size: cover;
    .first-slide__content {
      padding-bottom: rem(175);
    }
  }
  .btn {
    font-weight: 300;
    box-shadow: 0 rem(2 4) rgba(145, 161, 187, 0.100006);
    padding: rem(17 32 16);
    border-radius: rem(24);
  }
  &_small {
    background: $gray-bg-color;
    text-align: center;
    padding: rem(96) rem(20);
    p {
      text-align: center;
    }
    h1 {
      margin-bottom: rem(18);
    }
    .first-slide__date,
    .first-slide__descr {
      margin-bottom: 0;
    }
  }
  &_article {
    .container {
      max-width: rem(1352);
      position: relative;
    }
  }
}

.first-slide__content {
  padding: rem(120) 0 rem(144);
  max-width: rem(528);
}

.first-slide__descr,
.first-slide__date {
  font-size: rem(20);
  line-height: 1.6;
  margin-bottom: rem(52);
}

.first-slide__btn-container {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  .call-btn {
    margin-right: rem(16);
  }
}

.first-slide__img {
  position: absolute;
  right: rem(35);
  max-width: rem(524);
  top: rem(10);
}


@media (max-width: 1080px) {
  .first-slide {
    &.food {
      background-position-x: rem(-150);
    }
  }
  .first-slide {
    &.office {
      background-position-x: rem(-300);
    }
  }
}

@media (max-width: 992px) {
  .first-slide__img {
    max-width: rem(400);
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (max-width: 868px) {
  .first-slide {
    background-position-x: rem(-250);
  }
}

@media (max-width: 640px) {
  .first-slide__img {
    max-width: rem(300);
  }
}

@media (max-width: 540px) {
  .first-slide {
    background: $darken-gray-bg-color;
    &.food {
      background: $gray-bg-color;
    }
    &.office {
      background: #fff;
      .first-slide__content {
        padding-bottom: rem(96);
      }
    }
    &_small {
      background: $gray-bg-color;
    }
  }
  .first-slide__img {
    display: none;
  }
}

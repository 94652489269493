%icons-slick {
  display: block;
  width: 100%;
  height: 100%;
  background-position: 100%;
  color: #000;
  position: relative;
  z-index: 1;
}

.slick-prev {
  font-size: 0;
  right: rem(32);
  &:before {
    content: '';
    background: url('../img/left-arrow.svg') no-repeat;
    background-size: 100% 100%;
    @extend %icons-slick;
  }
}

.slick-next {
  font-size: 0;
  right: 0;
  &:before {
    content: '';
    background: url('../img/right-arrow.svg') no-repeat;
    background-size: 100% 100%;
    @extend %icons-slick;
  }
}

.slick-arrow {
  width: rem(24);
  height: rem(24);
  top: rem(-58);
  background: #fff;
  border: 1px solid $border-color;
  border-radius: rem(4);
  padding: rem(5);
  position: absolute;
  cursor: pointer;
  transition: border-color 250ms;
  &:hover, &:active, &:focus {
    border-color: $gray-text-color;
  }
}
